import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { FaArrowLeft } from 'react-icons/fa'

import Layout from '../components/layout'

interface INotFoundPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        by: string
      }
    }
  }
}

export const query = graphql`query NotFoundPageQuery {
  site {
    siteMetadata {
      title
      by
    }
  }
}`

export default class NotFoundPage extends React.Component<INotFoundPageProps, {}> {

  static goBack() {
    window.history.back()
  }

  render() {
    const { data: { site: { siteMetadata: meta } } } = this.props
    return (
      <Layout>
        <Helmet>
          <title>Errore 404 | {meta.title} di {meta.by}</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="hero is-fullheight-with-navbar">
          <div className="hero-body">
            <div className="container">
              <p className="title">404. Pagina non trovata</p>
              <p className="subtitle">Hai preso una strada sconosciuta&hellip;</p>
              <button type="button"
                      className="button"
                      onClick={NotFoundPage.goBack}>
                <span className="icon is-small">
                  <FaArrowLeft />
                </span>
                <span>Torna indietro</span>
              </button>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
